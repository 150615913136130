<template>
  <div id="app">
    <router-view />
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
import "@/assets/app.css";

export default {
  name: "App",
  // 创建前
  beforeCreate() {},
  // 创建后
  created() {
    if (this.env.DE_BUG === "true") {
      // 判断版本
      this.debug();
    }
    this.init();
  },
  // 挂载前
  beforeMount() {},
  // 挂载后
  mounted() {
    const thi = this;
    // 监听窗口变化
    window.onresize = function temp() {
      if (!thi.timer) {
        thi.timer = true;
        // 延迟操作
        setTimeout(function () {
          // 重新计算高度
          thi.setSmallScreen();
          thi.timer = false;
        }, 400);
      }
    };
  },
  // 更新前
  beforeUpdate() {
    this.Tool.document_title(this.$route.name);
  },
  // 更新后
  updated() {},
  // 销毁前
  beforeDestroy() {},
  // 销毁后
  destroyed() {},
  methods: {
    debug() {
      console.log("*****************开发测试版本*****************");
      console.log("当前配置", this.env);
      console.log("↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓程序开始↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓");
    },
    init() {
      console.log("入口文件，初始化");
      this.setSmallScreen();
    },
    // 设置是否是小屏幕（手机屏幕）
    setSmallScreen() {
      // 小屏幕
      if (window.innerWidth < 768) {
        this.$store.commit("set_small_screen", true);
      } else {
        this.$store.commit("set_small_screen", false);
      }
    },
  },
};
</script>

<style>

#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*text-align: center;*/
  /*margin-top: 60px;*/
}

</style>

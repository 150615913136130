import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "@/views/home/E404";
import store from "@/store/store";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "",
    component: () => import("@/views/index/Index"),
    children: [
      {
        path: "/",
        name: "官网",
        component: () => import("@/views/index/Page"),
      },
      {
        path: "/page",
        name: "页面",
        component: () => import("@/views/index/Page"),
      },
      {
        path: "/article",
        name: "文章页面",
        component: () => import("@/views/index/Article"),
      },
    ]
  },
  {
    path: "/saas",
    name: "企业管理平台",
    component: () => import("@/views/login/Login"),
  },
  {
    path: "/saas/wxbind",
    name: "关联微信",
    component: () => import("@/views/home/Wxbind"),
  },
  {
    path: "/saas/wxlogin",
    name: "微信登录",
    component: () => import("@/views/home/Wxlogin"),
  },
  {
    path: "/admin",
    name: "后台管理",
    component: () => import("@/views/login/Admin"),
  },
  {
    path: "/register",
    name: "企业注册",
    component: () => import("@/views/login/Register"),
  },
  {
    path: "/wechat",
    name: "微信小程序设置",
    component: () => import("@/views/wechat/index"),
  },
  {
    path: "",
    name: "Admin",
    component: () => import("@/layouts/AdminBasics"),
    children: [
      /*************主页基础************/
      {
        path: "/home",
        name: "主页",
        component: () => import("@/views/home/Home"),
      },
      {
        path: "/home/password",
        name: "修改密码",
        component: () => import("@/views/home/Password"),
      },
      /*************主页基础 end************/
      /*************微信公众号************/
      {
        path: "/wx/mp/info",
        name: "公众号信息",
        component: () => import("@/views/wx/mp/Info"),
      },
      /*************微信公众号end************/
      /*************系统设置************/
      {
        path: "/sys/role",
        name: "角色列表",
        component: () => import("@/views/sys/role/List"),
      },
      {
        path: "/sys/role/edit",
        name: "角色编辑",
        component: () => import("@/views/sys/role/Edit"),
      },
      {
        path: "/sys/user",
        name: "用户列表",
        component: () => import("@/views/sys/user/List"),
      },
      {
        path: "/sys/user/edit",
        name: "用户编辑",
        component: () => import("@/views/sys/user/Edit"),
      },
      {
        path: "/sys/log",
        name: "系统日志",
        component: () => import("@/views/sys/log/List"),
      },
      {
        path: "/sys/domain",
        name: "自定义域名",
        component: () => import("@/views/sys/domain/Info"),
      },
      {
        path: "/weapp/regionlimit",
        name: "用户地区限制",
        component: () => import("@/views/weapp/regionlimit/Info"),
      },
      /*************系统设置end************/
      /*************企业信息************/
      {
        path: "/company_examine_info",
        name: "企业信息",
        component: () => import("@/views/company/examine/Edit"),
      },
      /*************企业信息end************/
      /*************内容中心************/
      {
        path: "/content/class",
        name: "内容分类",
        component: () => import("@/views/content/class/List"),
      },
      {
        path: "/content/gather",
        name: "合集管理",
        component: () => import("@/views/content/gather/List"),
      },
      {
        path: "/content/gather/edit",
        name: "合集编辑",
        component: () => import("@/views/content/gather/Edit"),
      },
      {
        path: "/content",
        name: "内容管理",
        component: () => import("@/views/content/content/List"),
      },
      {
        path: "/content/edit",
        name: "内容编辑",
        component: () => import("@/views/content/content/Edit"),
      },
      /*************内容中心end************/

      /*************微信小程序************/
      {
        path: "/weapp/basicsset",
        name: "基础设置",
        component: () => import("@/views/weapp/basicsset/Edit"),
      },
      {
        path: "/weapp/menu",
        name: "导航设置",
        component: () => import("@/views/weapp/menu"),
      },
      {
        path: "/weapp/class",
        name: "分类管理",
        component: () => import("@/views/weapp/class"),
      },
      // {path: '/weapp/modular', name: '模块列表', component: () => import('@/views/weapp/modular/List')},
      // {path: '/weapp/modular/edit', name: '模块编辑', component: () => import('@/views/weapp/modular/Edit')},
      {
        path: "/weapp/page",
        name: "页面列表",
        component: () => import("@/views/weapp/page/List"),
      },
      {
        path: "/weapp/page/edit",
        name: "页面编辑",
        component: () => import("@/views/weapp/page/Edit"),
      },
      {
        path: "/weapp/rankingregion",
        name: "排行地区",
        component: () => import("@/views/weapp/rankingregion/Info"),
      },
      /*************微信小程序end************/
      /*************用户管理************/
      {
        path: "/user/member",
        name: "会员列表",
        component: () => import("@/views/user/member/List"),
      },
      {
        path: "/user/blackuser",
        name: "黑名单用户列表",
        component: () => import("@/views/user/member/Blackuser"),
      },
      {
        path: "/user/agreement",
        name: "会员协议",
        component: () => import("@/views/user/Agreement"),
      },
      /*************用户管理end************/
      /*************直播管理************/
      {
        path: "/broadcast/audio",
        name: "音频直播",
        component: () => import("@/views/broadcast/audio/List"),
      },
      {
        path: "/broadcast/audio/edit",
        name: "音频直播编辑",
        component: () => import("@/views/broadcast/audio/Edit"),
      },
      {
        path: "/broadcast/video",
        name: "视频直播",
        component: () => import("@/views/broadcast/video/List"),
      },
      {
        path: "/broadcast/video/edit",
        name: "视频直播编辑",
        component: () => import("@/views/broadcast/video/Edit"),
      },
      {
        path: "/broadcast/weapp",
        name: "小程序直播",
        component: () => import("@/views/broadcast/weapp/Info"),
      },
      /*************直播管理end************/
      // 商城
      {
        path: "/goods/brand",
        name: "品牌管理",
        component: () => import("@/views/goods/brand/list"),
      },
      {
        path: "/goods/class",
        name: "商品分类",
        component: () => import("@/views/goods/class/list"),
      },
      {
        path: "/goods/specifications",
        name: "规格管理",
        component: () => import("@/views/goods/specifications/list"),
      },
      {
        path: "/goods/list",
        name: "商品管理",
        component: () => import("@/views/goods/list/list"),
      },
        {
            path: "/goods/order",
            name: "商品订单",
            component: () => import("@/views/goods/order/index"),
        },
      {
        path: "/goods/drawback",
        name: "退款管理",
        component: () => import("@/views/goods/drawbackApply/index"),
      },
      {
        path: "/goods/list/edit",
        name: "商品详情",
        component: () => import("@/views/goods/list/edit"),
      },
      {
        path: "/goods/coupon",
        name: "优惠券",
        component: () => import("@/views/goods/coupon/List"),
      },
      {
        path: "/goods/coupon/edit",
        name: "优惠券详情",
        component: () => import("@/views/goods/coupon/Edit"),
      },
      {
        path: "/goods/broadcastlist",
        name: "直播商品库",
        component: () => import("@/views/goods/broadcast/list"),
      },
      /*************投票************/
      {
        path: "/markettool/vote",
        name: "投票",
        component: () => import("@/views/vote/List"),
      },
      /*************成长体系管理************/
      {
        path: "/weapp/member",
        name: "成长体系管理",
        component: () => import("@/views/weapp/member/index"),
      },
      /*************积分配置管理************/
      {
        path: "/weapp/integral",
        name: "积分配置管理",
        component: () => import("@/views/weapp/integral/index"),
      },
      {
        path: "/markettool/vote/edit",
        name: "投票编辑",
        component: () => import("@/views/vote/Edit"),
      },
      /*************投票end************/
      /*************官网设置************/
      {
        path: "/website/basic",
        name: "官网基础设置",
        component: () => import("@/views/website/basic/Info"),
      },
      {
        path: "/website/pages",
        name: "官网页面",
        component: () => import("@/views/website/pages/List"),
      },
      {
        path: "/website/pages/edit",
        name: "官网页面-编辑",
        component: () => import("@/views/website/pages/Edit"),
      },
      {
        path: "/website/guestbook",
        name: "留言板",
        component: () => import("@/views/website/guestbook/List"),
      },
      /*************官网设置************/
    ],
  },
  { path: "*", name: "NotFound", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  store.commit("setIsScroll", false);
  next();
});

export default router;

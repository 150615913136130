import env from '@/../config/env'
// import auth from '@/../config/auth'      // 本地权限
import apiurl from '@/../config/apiurl'
import axios from 'axios'
import {Message, MessageBox} from 'element-ui';

const tool = {

    // 判断是否是空的
    is_empty: (data = '') => {
        // 判断空对象
        if (JSON.stringify(data) === '{}') return true
        // 判断空数组
        if (JSON.stringify(data) === '[]') return true
        // 判断空字符串
        if (data === '') return true
        // 判断undefined
        if (data === undefined) return true
        // 判断undefined
        if (data === null) return true
        return false
    },
    // 错误消息弹出
    errormes: (json, callbackfun) => {

        if (json.code === 10002) { // 普通提示
            Message({
                message: json.message,
                duration: env.message_error,
                onClose: () => {
                    callbackfun && callbackfun()
                }
            });
        } else if (json.code === 10005) {
            Message({
                message: json.message, type: 'warning',
                duration: env.message_error,
                onClose: () => {
                    callbackfun && callbackfun()
                }
            });
        } else if (json.code === 0) {
            if (json.message === 'ok') {
                json.message = '操作成功'
            }
            Message({
                message: json.message, type: 'success',
                duration: env.message_duration,
                onClose: () => {
                    callbackfun && callbackfun()
                }
            });
        } else if (json.code === 10000) {
            console.log(json)
            let message = '<p style="line-height: 20px;">'
            for (let val_tip of json.message_all) {
                message += `${val_tip}</br>`
            }
            message += '</p>'

            MessageBox.alert(message, '信息提醒', {
                confirmButtonText: '关闭',
                dangerouslyUseHTMLString: true,
                type: 'error',
                callback: action => {
                    callbackfun && callbackfun(action)
                }
            });

        } else if (json.code === 101) {
            console.log(json)
            MessageBox.confirm(json.message, '提示', {
                closeOnClickModal: false,
                confirmButtonText: '前往补充信息',
                cancelButtonText: '我先看看',
                type: 'warning'
            }).then(() => {
                location.href = '/company_examine_info'
            }).catch(() => {

            });
        } else {
            Message({
                message: json.message, type: 'error',
                duration: env.message_error,
                onClose: () => {
                    callbackfun && callbackfun()
                }
            });
        }
    },
    // POST请求
    post_data: (url, postData = {}, callbackfun, funcCatch, excel = 0) => {
        // 判断请求地址
        let qurl = env.API_URL          // 请求地址
        const token = tool.get_l_cache('token') ?? ''   // 请求token

        if (apiurl[url] !== undefined) {
            qurl += apiurl[url].url
            // 判断是否需要登录
            if (apiurl[url].needLogin) {
                if (token == '') {
                    // 跳转到登录页面
                    console.log('请先登录')
                    Message.error({
                        message: '请先登录',
                        onClose: () => {
                            location.href = '/'
                        }
                    });
                    return
                } else {
                    postData.token = token
                }
            }
            // 判断是否需要授权
        } else {
            if (url.substring(0, 4) === 'http') {
                qurl = url
            } else {
                qurl += '/javaadmin/' + url
                // qurl = 'https://testfrp1.xqgxyun.com/' + url
            }
        }

        // DE_BUG 模式打印请求参数
        if (env.DE_BUG === 'true') {
            console.log('******************************DE_BUG模式***POST请求*******************')
            console.log('请求地址', qurl)
            console.log('请求参数', postData)
        }

        // 如果有token 默认加上token
        if (token !== '') {
            postData.token = token;
        }
        let config = {
            headers: {
                'api-name': postData.api_name,
                token: token
            }
        }

        // 判断是否导出数据
        if (excel === 1) {
            config.responseType = 'blob'
        }
        axios.post(qurl, postData, config)
            .then(function (response) {
                if (env.DE_BUG === 'true') {
                    console.log('返回参数body', response.data)
                    console.log('***************************DE_BUG模式***POST请求***结束****************')
                }

                if (response.status === 200) {
                    // if (response.data.code === 10005) {
                    //     tool.del_l_cache('user')
                    //     location.href = '/'
                    //     return
                    // }
                    callbackfun && callbackfun(response.data)
                }
            })
            .catch(function (error) {
                console.log('系统错误', error)
                Message.error('系统错误：' + error.message);
                funcCatch && funcCatch(error)
            })
    },
    // GET请求
    get_data: (url, postData = {}, callbackfun) => {
        // 判断请求地址
        let qurl = env.API_URL

        if (apiurl[url] !== undefined) {
            qurl += apiurl[url].url
            // 判断是否需要登录
            if (apiurl[url].needLogin) {
                const token = tool.get_l_cache('token') ?? ''
                if (token == '') {
                    // 跳转到登录页面
                    console.log('请先登录')
                    Message.error({
                        message: '请先登录',
                        onClose: () => {
                            location.href = '/'
                        }
                    });
                    return
                } else {
                    postData.token = token
                }
            }
            // 判断是否需要授权

        } else {
            if (url.substring(0, 4) === 'http') {
                qurl = url
            } else {
                qurl += '/' + url
            }
        }

        // DE_BUG 模式打印请求参数
        if (env.DE_BUG === 'true') {
            console.log('******************************DE_BUG模式***GET请求*******************')
            console.log('请求地址', qurl)
            console.log('请求参数', postData)

        }
        axios.get(qurl, {params: postData})
            .then(function (response) {
                if (env.DE_BUG === 'true') {
                    console.log('返回参数body', response.data)
                    console.log('***************************DE_BUG模式***GET请求***结束****************')
                }
                if (response.status === 200) {
                    callbackfun && callbackfun(response.data)
                }
            })
            .catch(function (error) {
                console.log('系统错误', error)
                Message.error('系统错误：' + error.message);
            })
    },
    // 导出表格数据
    excel_export: (res, fileName = '导出文件') => {
        // console.log(res)
        const blob = new Blob([res], {
            // type: "application/vnd.ms-excel"
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })

        const linkNode = document.createElement('a')
        // eslint-disable-next-line prefer-const
        linkNode.download = fileName // a标签的download属性规定下载文件的名称
        linkNode.href = URL.createObjectURL(blob) // 生成一个Blob URL
        linkNode.style.display = 'none'
        document.body.appendChild(linkNode)
        linkNode.click() // 模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href) // 释放URL 对象
        document.body.removeChild(linkNode)
    },
    // 缓存处理
    s_cache: (key, val = undefined) => {
        if (val === undefined) {
            // 获取缓存
            return JSON.parse(sessionStorage.getItem(key))
        }
        if (val === null) {
            // 删除缓存
            if (key === 'all') {
                return sessionStorage.clear()
            } else {
                return sessionStorage.removeItem(key)
            }
        }
        // 设置缓存
        return sessionStorage.setItem(key, JSON.stringify(val))
    },
    set_s_cache: (key, val,) => {
        return sessionStorage.setItem(key, JSON.stringify(val))
    },
    get_s_cache: key => {
        return JSON.parse(sessionStorage.getItem(key))
    },
    del_s_cache: key => {
        if (key === 'all') {
            return sessionStorage.clear()
        } else {
            return sessionStorage.removeItem(key)
        }
    },
    l_cache: (key, val = undefined) => {
        if (val === undefined) {
            // 获取缓存
            return JSON.parse(localStorage.getItem(key))
        }
        if (val === null) {
            // 删除缓存
            if (key === 'all') {
                return localStorage.clear()
            } else {
                return localStorage.removeItem(key)
            }
        }
        // 设置缓存
        return localStorage.setItem(key, JSON.stringify(val))
    },
    set_l_cache: (key, val) => {
        return localStorage.setItem(key, JSON.stringify(val))
    },
    get_l_cache: key => {
        return JSON.parse(localStorage.getItem(key))
    },
    del_l_cache: key => {
        if (key === 'all') {
            return localStorage.clear()
        } else {
            return localStorage.removeItem(key)
        }
    },
    set_c_cache: (key, val, time = 0) => {
        // 设置cookie方法
        let date = new Date()
        let expiresDays = time
        date.setTime(date.getTime() + expiresDays * 1000)
        document.cookie = key + '=' + val + ';expires=' + date.toGMTString()
    },
    get_c_cache: key => {
        let getCookie = document.cookie.replace(/[ ]/g, '')
        let arrCookie = getCookie.split(';')
        let value
        for (let i = 0; i < arrCookie.length; i++) {
            let arr = arrCookie[i].split('=')
            if (key === arr[0]) {
                value = arr[1]
                break
            }
        }
        return value
    },
    del_c_cache: key => {
        if (key === 'all') {
            let keys = document.cookie.match(/[^ =;]+(?==)/g)
            if (keys) {
                for (let i = keys.length; i--;) {
                    document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
                }
            }
        } else {
            let date = new Date()
            date.setTime(date.getTime() - 10000)
            document.cookie = key + '=v; expires =' + date.toGMTString()
        }
    },
    // 判断权限
    is_auth: val => {
        // 用户权限
        return tool.l_cache('userauth').includes(val);
    },
    // 加
    bcadd(arg1, arg2) {
        let r1, r2, m;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        return Number((arg1 * m + arg2 * m) / m)
    },
    // 减
    bcsub(arg1 = '', arg2 = '') {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //last modify by deeka
        //动态控制精度长度
        n = (r1 >= r2) ? r1 : r2;
        return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
    },
    // 乘
    bcmul(arg1 = 0, arg2 = 1) {
        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            if (!tool.is_empty(s1.split(".")[1])) {
                m += s1.split(".")[1].length
            } else {
                m += 0
            }
        } catch (e) {
            console.log(e)
        }
        try {
            if (!tool.is_empty(s2.split(".")[1])) {
                m += s2.split(".")[1].length
            } else {
                m += 0
            }
        } catch (e) {
            console.log(e)
        }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    },
    // 时间判断
    time_decide(time, decide = '<', time2 = 'now') {
        let date1 = new Date(time).getTime()        // 判断时间
        let date2 = new Date().getTime()            // 当前时间
        if (time2 !== 'now') {
            date2 = new Date(time2).getTime()       // 判断时间2
        }

        if (decide === '>') {
            if (date1 > date2) {
                return true
            } else {
                return false
            }
        } else {
            if (date1 < date2) {
                return true
            } else {
                return false
            }
        }
    },
    // 更改页面标题
    document_title(name = '') {
        document.title = name
    },
    //获取字符
    get_random_string: (len) => {
        len = len || 32;
        let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        let maxPos = $chars.length;
        let randomString = '';
        for (let i = 0; i < len; i++) {
            randomString += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return randomString;
    },
    // 获取预定义颜色
    getPredefineColors(colors = []) {
        // console.log('指定预设', colors)
        let colorsAll = colors.concat([
            '#ff4500',
            '#ff8c00',
            '#ffd700',
            '#90ee90',
            '#00ced1',
            '#1e90ff',
            '#c71585',
            '#F2F6FC',
            '#ffffff',
            '#000000',
        ])
        return [...new Set(colorsAll)]
    },

}

export default tool

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import env from "/config/env"; // 自定义配置
import tool from "./assets/tool"; // 自定义工具
import "font-awesome/css/font-awesome.min.css";
// 引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
//引入windcss
import "windi.css";
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/nutui.css";
import "@/assets/icons/iconfont.css";
// 引入tabbar组件
import {
  Tabbar,
  TabbarItem,
  Icon,
  Sidebar,
  SidebarItem,
  Search,
  Tab,
  Tabs,
  Button,
  Swipe,
  Empty,
  SwipeItem,
  Cell,
  CellGroup,
  Slider,
  Image as VanImage,
} from "vant";
import "vant/lib/image/style";
import "vant/lib/tabbar/style";
import "vant/lib/tabbar-item/style";
import "vant/lib/sidebar/style";
import "vant/lib/sidebar-item/style";
import "vant/lib/search/style";
import "vant/lib/tab/style";
import "vant/lib/tabs/style";
import "vant/lib/button/style";
import "vant/lib/swipe/style";
import "vant/lib/swipe-item/style";
import "vant/lib/empty/style";
import "vant/lib/cell/style";
import "vant/lib/slider/style";
import waterfall from "vue-waterfall2";

Vue.use(waterfall);
Vue.use(Tabbar);
Vue.use(VanImage);
Vue.use(TabbarItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Search);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Empty);
Vue.use(Slider);

NutUI.install(Vue);
Vue.use(ElementUI, { size: "small" });
import "mavon-editor/dist/css/index.css";
// 引入图片裁剪
import VueCropper from "vue-cropper";

Vue.use(VueCropper);

Vue.config.productionTip = false;

// 复制扩展
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
// 全局自定义内容
Vue.prototype.env = env;
Vue.prototype.Tool = tool;

// 1.导入store对象
import store from "./store/store";

import "windi.css";

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import Vuex from "vuex";
import Vue from "vue";

// 1.安装插件
Vue.use(Vuex);

// 2.创建对象
const store = new Vuex.Store({
  state: {
    small_screen: false, // 是否小屏幕
    count: 0,
    test: 120,
    isScroll: false,
  },
  mutations: {
    // 设置大小屏幕
    set_small_screen(state, val) {
      state.small_screen = val;
    },
    increment(state) {
      state.test++;
    },
    setIsScroll(state, payload) {
      state.isScroll = payload;
    },
  },
  actions: {},
  getters: {},
  modules: {},
});

// 导出store对象
export default store;

// 本地权限列表
let env = {
    // 开发模式-默认否
    DE_BUG: process.env.VUE_APP_DE_BUG ?? 'false',
    // ICP号
    ICP: process.env.VUE_APP_ICP ?? '',
    // 系统名称
    copyright_name: process.env.VUE_APP_COPYRIGHT ?? '',
    // 接口地址
    API_URL: process.env.VUE_APP_API_URL ?? 'https://testapi.xqgxyun.com',
    // 分页条数
    pageSize: 10,
    // label默认宽度
    label_width: '120px',
    search_label_width: '80px',
    // 尺寸
    table_size: 'medium',           // 表格尺寸
    button_size: 'small',           // 按钮尺寸
    form_size: 'small',             // 表单尺寸
    el_col_md: {span: 20, offset: 1},
    el_col_lg: {span: 14, offset: 2},
    // message默认提示时间毫秒
    message_duration: 1000,
    message_error: 2000,
    // 高德地图配置
    gaodekey: process.env.VUE_APP_AMAP_KEY ?? '',
    gaodesecret: process.env.VUE_APP_AMAP_SECRET ?? '',
}
export default env

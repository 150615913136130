let apiurl = {
    api: {
        url: '/admin',     // 具体地址
        needLogin: false,      // 是否需要登录
        needAuth: false,        // 是否需要授权认证
    },
    h5api: {
        url: '/h5',     // 具体地址
        needLogin: false,      // 是否需要登录
        needAuth: false,        // 是否需要授权认证
    },
}
export default apiurl
